import { createSlice } from '@reduxjs/toolkit';

export type TSearchState = boolean;

const initialState: TSearchState = false;

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearch: (_state, action) => action.payload,
  },
});

export const { setSearch } = searchSlice.actions;

export default searchSlice.reducer;
