import { useEffect } from 'react';

export const useDisableScroll = (parameter: boolean) => {
  useEffect(() => {
    if (parameter) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [parameter]);
};
