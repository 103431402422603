import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { FieldServiceCaseResponse } from '@signpost-hardware/management-portal-models/v1/field-service/cases/responses/field_service_case_response_pb';
import { DeviceResponse } from '@signpost-hardware/management-portal-models/v1/devices/responses/device_response_pb';
import { UserResponse } from '@signpost-hardware/management-portal-models/v1/users/responses/user_response_pb';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSearch } from '../../redux/slices/searchSlice.ts';
import { TranslationContext } from '../../context/translationContext.ts';

type TProps = {
  cases?: FieldServiceCaseResponse[];
  devices?: DeviceResponse[];
  users?: UserResponse[];
  searchString: string;
};

const SearchList = (props: TProps) => {
  const { cases, devices, users, searchString } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [recentData] = useState<
    FieldServiceCaseResponse[] | UserResponse[] | DeviceResponse[]
  >(JSON.parse(localStorage.getItem('recentData') ?? '[]'));
  const translation = useContext(TranslationContext);

  const handleShowAllResults = () => {
    dispatch(setSearch(false));
    navigate(`/search?q=${searchString}`);
  };

  const storeObjectToRecentData = (
    newGuid: string,
    currentObject: FieldServiceCaseResponse | UserResponse | DeviceResponse,
    type: string,
  ) => {
    const typeObject = { ...currentObject, type: type };
    let newObject:
      | FieldServiceCaseResponse
      | UserResponse
      | (DeviceResponse & {
          recentCase?: typeof typeObject;
          recentDevice?: typeof typeObject;
          recentUser?: typeof typeObject;
        }) = currentObject;

    const recentDataTemp = [...recentData];
    const index = recentDataTemp.findIndex((item) => item.guid === newGuid);

    if (index === -1) {
      if (type === 'incident') {
        newObject = { ...typeObject, recentCase: typeObject };
      } else if (type === 'device') {
        newObject = { ...typeObject, recentDevice: typeObject };
      } else if (type === 'user') {
        newObject = { ...typeObject, recentUser: typeObject };
      }

      if (recentDataTemp.length > 3) recentDataTemp.pop();
      recentDataTemp.unshift(newObject);
      localStorage.setItem('recentData', JSON.stringify(recentDataTemp));
    }
  };

  return (
    <section className='max-h-[27rem] overflow-y-auto pb-16 pt-3'>
      <ul role='list'>
        {devices && devices.length > 0 && (
          <>
            <div className='flex items-center gap-x-2 px-4 py-4'>
              <span className='bg-app-500 text-app-100 text-sm me-2 px-2.5 py-1 rounded-lg'>
                {translation.device}
              </span>
              <div className='flex-grow border-t border-alpha-200'></div>
            </div>
            {devices.map((device, index) => (
              <li key={`${device.guid}_${index}`}>
                <Link
                  onClick={() => {
                    dispatch(setSearch(false));
                    storeObjectToRecentData(
                      device.guid || '',
                      device,
                      'device',
                    );
                  }}
                  to={`/devices/${device.guid}`}
                  className='group bg-app-200 hover:bg-app-500 hover:text-alpha cursor-pointer flex justify-between gap-x-6 py-1 mb-1 min-w-60'>
                  <div className='min-w-0 flex-auto px-4 py-3'>
                    <p>
                      {device.manufacturer} {device.modelName}
                    </p>
                    {device.serialNumber && (
                      <p className='text-sm  font-light'>
                        {device.serialNumber}
                      </p>
                    )}
                    {(device.institutionLabel || device.signpostLabel) && (
                      <p className='text-sm  font-light'>
                        {device.institutionLabel ?? device.signpostLabel}
                      </p>
                    )}
                    {device.user?.firstName && device.user?.lastName && (
                      <p className='truncate text-sm font-light'>
                        {device.user?.firstName} {device.user?.lastName}
                      </p>
                    )}
                  </div>
                  <div className='flex justify-between items-center gap-x-2 px-2'>
                    <FontAwesomeIcon
                      className='opacity-0 group-hover:opacity-75 hover:text-alpha'
                      icon={faChevronRight}
                      size='sm'
                    />
                  </div>
                </Link>
              </li>
            ))}
          </>
        )}
        {users && users.length > 0 && (
          <>
            <div className='flex items-center gap-x-2 px-4 py-4'>
              <span className='bg-app-500 text-alpha text-sm me-2 px-2.5 py-1 rounded-lg'>
                {translation.user}
              </span>
              <div className='flex-grow border-t border-alpha-200'></div>
            </div>
            {users.map((user, index) => (
              <li key={`${user.guid}_${index}`}>
                <Link
                  onClick={() => {
                    dispatch(setSearch(false));
                    storeObjectToRecentData(user.guid || '', user, 'user');
                  }}
                  to={`/users/${user.guid}`}
                  className='group bg-app-200 hover:bg-app-500 hover:text-alpha cursor-pointer flex justify-between gap-x-6 py-1 mb-1 min-w-60'>
                  <div className='min-w-0 flex-auto px-3 py-2'>
                    <p>
                      {user.firstName} {user.lastName}
                    </p>
                    <p className='text-sm'>{user.email}</p>
                    {user.devices[0] && (
                      <p className='text-sm font-light'>
                        {user.devices[0].serialNumber}
                      </p>
                    )}
                  </div>
                  <div className='flex justify-between items-center gap-x-2 px-2'>
                    <FontAwesomeIcon
                      className='opacity-0 group-hover:opacity-75 hover:text-app-100'
                      icon={faChevronRight}
                      size='sm'
                    />
                  </div>
                </Link>
              </li>
            ))}
          </>
        )}
        {cases && cases.length > 0 && (
          <>
            <div className='flex items-center gap-x-2 px-4 py-4'>
              <span className='bg-app-500 text-alpha text-sm me-2 px-2.5 py-1 rounded-lg'>
                {translation.incident}
              </span>
              <div className='flex-grow border-t border-alpha-200'></div>
            </div>
            {cases.map((fsCase, index) => (
              <li key={`${fsCase.guid}_${index}`}>
                <Link
                  onClick={() => {
                    dispatch(setSearch(false));
                    storeObjectToRecentData(fsCase.guid, fsCase, 'incident');
                  }}
                  to={`/incidents/${fsCase.guid}`}
                  className='group bg-app-200 hover:bg-app-500 hover:text-alpha cursor-pointer flex justify-between gap-x-6 py-1 mb-1 min-w-60'>
                  <div className='min-w-0 flex-auto px-3 py-2'>
                    <p className='leading-6 '>
                      #{fsCase.ourReference} - {fsCase.status}
                    </p>
                    {fsCase.billingContact && (
                      <p className='text-sm font-light'>
                        {fsCase.billingContact.firstName}{' '}
                        {fsCase.billingContact.lastName}
                      </p>
                    )}
                    {fsCase.serialNumber && (
                      <p className='text-sm font-light'>
                        {fsCase.serialNumber}
                      </p>
                    )}
                  </div>
                  <div className='flex justify-between items-center gap-x-2 px-2'>
                    <FontAwesomeIcon
                      className='opacity-0 group-hover:opacity-75 hover:text-app-100'
                      icon={faChevronRight}
                      size='sm'
                    />
                  </div>
                </Link>
              </li>
            ))}
          </>
        )}
      </ul>
      <button
        type='button'
        className='fixed bottom-0 w-full bg-app py-3 text-sm text-alpha font-extralight'
        onClick={handleShowAllResults}>
        {translation.showAllResults}
        <FontAwesomeIcon icon={faArrowRight} className='ml-2' size='sm' />
      </button>
    </section>
  );
};

export default SearchList;
