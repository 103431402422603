import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { FieldServiceCaseResponse } from '@signpost-hardware/management-portal-models/v1/field-service/cases/responses/field_service_case_response_pb';
import { DeviceResponse } from '@signpost-hardware/management-portal-models/v1/devices/responses/device_response_pb';
import { UserResponse } from '@signpost-hardware/management-portal-models/v1/users/responses/user_response_pb';
import { useQuery } from '@tanstack/react-query';
import { fetchUser } from '../../helpers/axios/configs/userAxios.ts';
import { fetchFieldService } from '../../helpers/axios/configs/fieldServiceAxios.ts';
import { fetchDevice } from '../../helpers/axios/configs/deviceAxios.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faSpinnerThird,
} from '@fortawesome/pro-solid-svg-icons';
import { TGenericWidget } from '../widgets/index.ts';
import { useContent } from '../../hooks/useContent.ts';

enum EButtonData {
  ALL = 'all',
  INCIDENT = 'incident',
  DEVICE = 'device',
  USER = 'user',
}

type TSortButton = {
  type: EButtonData;
  label: string;
  count: number;
};

type TSortButtonProps = TSortButton & {
  isActive: boolean;
  onClick: () => void;
};

const SortButton = (props: TSortButtonProps) => {
  const { label, count, isActive, onClick } = props;

  return (
    <button
      onClick={onClick}
      className={`${
        isActive ? 'bg-app-700 text-app-100' : 'bg-alpha'
      } group text-sm px-3 py-1 rounded-lg flex justify-between items-center gap-4 hover:bg-app-700 hover:text-app-100 transition-colors border min-w-40 mb-2`}>
      {label}
      <span
        className={`${
          isActive ? 'bg-app-500 text-app-100' : 'bg-app-700'
        } text-app-100 transition-colors group-hover:bg-app-500 inline-flex items-center rounded-md px-3 py-1 text-xs font-medium`}>
        {count}
      </span>
    </button>
  );
};

const SearchResults = ({ content, schemaName }: TGenericWidget) => {
  const { data: translation } = useContent(schemaName, content[0]);
  const [activeButton, setActiveButton] = useState<EButtonData>(
    EButtonData.ALL,
  );
  const [searchParams] = useSearchParams();
  const query = searchParams.get('q');

  const { data: devices, isPending: isPendingDevices } = useQuery<
    DeviceResponse[]
  >({
    queryKey: ['devices', query],
    queryFn: async () => {
      const res = await fetchDevice.get('', {
        params: { $search: query },
      });
      return res.data.items;
    },
    enabled: !!query,
  });

  const { data: cases, isPending: isPendingCases } = useQuery<
    FieldServiceCaseResponse[]
  >({
    queryKey: ['cases', query],
    queryFn: async () => {
      const res = await fetchFieldService.get('/cases', {
        params: { $expand: 'Device', $search: query },
      });
      return res.data.items;
    },
    enabled: !!query,
  });

  const { data: users, isPending: isPendingUsers } = useQuery<UserResponse[]>({
    queryKey: ['users', query],
    queryFn: async () => {
      const res = await fetchUser.get('', {
        params: { $search: query },
      });
      return res.data.items;
    },
    enabled: !!query,
  });

  const buttonsData: TSortButton[] = [
    {
      type: EButtonData.ALL,
      label: translation?.all ?? '',
      count:
        (cases?.length || 0) + (devices?.length || 0) + (users?.length || 0),
    },
    {
      type: EButtonData.USER,
      label: translation?.users ?? '',
      count: users?.length || 0,
    },
    {
      type: EButtonData.DEVICE,
      label: translation?.devices ?? '',
      count: devices?.length || 0,
    },
    {
      type: EButtonData.INCIDENT,
      label: translation?.incidents ?? '',
      count: cases?.length || 0,
    },
  ];

  const handleSorting = (param1: string, param2: string) => {
    param1 = param1.toLowerCase();
    param2 = param2.toLowerCase();

    if (param1 < param2) return -1;
    if (param1 > param2) return 1;
    return 0;
  };

  const casesList =
    cases &&
    cases.length > 0 &&
    cases
      .sort((a, b) => handleSorting(a.ourReference || '', b.ourReference || ''))
      .map((fsCase) => (
        <li key={fsCase.guid}>
          <Link
            to={`/incidents/${fsCase.guid}`}
            className='group bg-app-200 hover:bg-app-500 hover:text-alpha cursor-pointer flex justify-between gap-x-6 py-1 rounded-lg mb-1 min-h-20 min-w-80 max-w-[48rem]'>
            <div className='min-w-0 flex-auto px-3 py-2'>
              <p className='leading-6 text-gray-900'>
                #{fsCase.ourReference} - {fsCase.status}
              </p>
              {fsCase.billingContact && (
                <p className='text-sm font-light'>
                  {fsCase.billingContact.firstName}{' '}
                  {fsCase.billingContact.lastName}
                </p>
              )}
              {fsCase.serialNumber && (
                <p className='text-sm font-light'>{fsCase.serialNumber}</p>
              )}
            </div>
            <div className='flex justify-between items-center gap-x-2 px-2'>
              <FontAwesomeIcon
                className='opacity-0 group-hover:opacity-75 hover:text-app-100'
                icon={faChevronRight}
                size='sm'
              />
            </div>
          </Link>
        </li>
      ));

  const devicesList =
    devices &&
    devices.length > 0 &&
    devices
      .sort((a, b) =>
        handleSorting(
          `${a.manufacturer} ${a.modelName}`,
          `${b.manufacturer} ${b.modelName}`,
        ),
      )
      .map((device) => (
        <li key={device.guid}>
          <Link
            to={`/devices/${device.guid}`}
            className='group bg-app-200 hover:bg-app-500 hover:text-alpha cursor-pointer flex justify-between gap-x-6 py-1 rounded-lg mb-1 min-h-20 min-w-80 max-w-[48rem]'>
            <div className='min-w-0 flex-auto px-4 py-3'>
              <p className='text-gray-900'>
                {device.manufacturer} {device.modelName}
              </p>
              {device.serialNumber && (
                <p className='text-sm text-gray-900 font-light'>
                  {device.serialNumber}
                </p>
              )}
              {(device.institutionLabel || device.signpostLabel) && (
                <p className='text-sm text-gray-900 font-light'>
                  {device.institutionLabel || device.signpostLabel}
                </p>
              )}
              {device.user?.firstName && device.user?.lastName && (
                <p className='truncate text-sm font-light'>
                  {device.user?.firstName} {device.user?.lastName}
                </p>
              )}
            </div>
            <div className='flex justify-between items-center gap-x-2 px-2'>
              <FontAwesomeIcon
                className='opacity-0 group-hover:opacity-75 hover:text-app-100'
                icon={faChevronRight}
                size='sm'
              />
            </div>
          </Link>
        </li>
      ));

  const usersList =
    users &&
    users.length > 0 &&
    users
      .sort((a, b) =>
        handleSorting(
          `${a.firstName} ${a.lastName}`,
          `${b.firstName} ${b.lastName}`,
        ),
      )
      .map((user) => (
        <li key={user.guid}>
          <Link
            to={`/users/${user.guid}`}
            className='group bg-app-200 hover:bg-app-500 hover:text-app-100 cursor-pointer flex justify-between gap-x-6 py-1 rounded-lg mb-1 min-h-20 min-w-80 max-w-[48rem]'>
            <div className='min-w-0 flex-auto px-3 py-2'>
              <p>
                {user.firstName} {user.lastName}
              </p>
              <p className='text-sm'>{user.email}</p>
              {user.devices[0] && (
                <p className='text-sm font-light'>
                  {user.devices[0].serialNumber}
                </p>
              )}
            </div>
            <div className='flex justify-between items-center gap-x-2 px-2'>
              <FontAwesomeIcon
                className='opacity-0 group-hover:opacity-75 hover:text-app-100'
                icon={faChevronRight}
                size='sm'
              />
            </div>
          </Link>
        </li>
      ));

  const allData = (
    <>
      {usersList}
      {devicesList}
      {casesList}
    </>
  );

  return (
    <>
      <div className='flex flex-col gap-4'>
        <div className='md:flex md:flex-row md:space-x-2 sm:flex sm:flex-col sm:items-start'>
          {buttonsData.map((buttonData) => (
            <SortButton
              key={buttonData.type}
              {...buttonData}
              isActive={activeButton === buttonData.type}
              onClick={() => setActiveButton(buttonData.type)}
            />
          ))}
        </div>
        {(isPendingCases || isPendingDevices || isPendingUsers) && (
          <FontAwesomeIcon
            icon={faSpinnerThird}
            spin
            className='text-app-600'
            size='lg'
          />
        )}
      </div>
      <div className='pb-2 pt-3 sm:pb-2'>
        <ul role='list'>
          {activeButton === EButtonData.INCIDENT && casesList}
          {activeButton === EButtonData.DEVICE && devicesList}
          {activeButton === EButtonData.USER && usersList}
          {activeButton === EButtonData.ALL && allData}
        </ul>
      </div>
    </>
  );
};

export default SearchResults;
