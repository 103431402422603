type TSearchAlertProps = { message: string; searchParam?: string };

const SearchAlert = ({ message, searchParam }: TSearchAlertProps) => {
  return (
    <div className='bg-white flex justify-center mt-40'>
      <p className='leading-6 text-alpha-700'>
        {message}{' '}
        {searchParam && (
          <span className='font-semibold text-app-800'>"{searchParam}"</span>
        )}
      </p>
    </div>
  );
};

export default SearchAlert;
