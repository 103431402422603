import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

type TClickOutside = {
  ignoreClass?: string | string[];
};

type TRef<P> = RefObject<P>;

export type TCustomStateClickOutside = TClickOutside & {
  customState: boolean;
  customToggleState:
    | Dispatch<SetStateAction<boolean>>
    | ((value: boolean) => void);
};

export type TDefaultReturn<P> = [
  boolean,
  Dispatch<SetStateAction<boolean>>,
  TRef<P>,
];

export type TCustomStateReturn<P> = [TRef<P>];

const checkClass = (element: HTMLElement, className: string): boolean =>
  element.classList?.contains(className);

const hasIgnoreClass = (
  e: HTMLElement,
  ignoreClass: string | string[],
): boolean => {
  let element: HTMLElement | null = e;
  while (element) {
    if (Array.isArray(ignoreClass)) {
      if (
        ignoreClass.some((className) =>
          checkClass(element as HTMLElement, className),
        )
      )
        return true;
    } else if (checkClass(element as HTMLElement, ignoreClass)) {
      return true;
    }
    element = element.parentElement;
  }

  return false;
};

function isCustomStateClickOutside(
  options: TClickOutside | TCustomStateClickOutside,
): options is TCustomStateClickOutside {
  return (options as TCustomStateClickOutside).customState !== undefined;
}

export const useClickOutside = <
  P extends HTMLElement,
  T extends TClickOutside | TCustomStateClickOutside = TClickOutside,
  R = T extends TCustomStateClickOutside
    ? TCustomStateReturn<P>
    : TDefaultReturn<P>,
>(
  props?: T,
): R => {
  const elementRef = useRef<HTMLElement>(null);
  const [togglestate, setToggleState] = useState<boolean>(false);

  const handleWindowClick = useCallback(
    (e: MouseEvent): void => {
      e.stopPropagation();
      if (
        elementRef.current &&
        !elementRef.current.contains(e.target as Node) &&
        !hasIgnoreClass(
          e.target as HTMLElement,
          props?.ignoreClass ?? 'ignoreClickOutside',
        )
      ) {
        if (props && isCustomStateClickOutside(props))
          props.customToggleState(false);
        else setToggleState(false);
      }
    },
    [props],
  );

  useEffect(() => {
    if (
      togglestate ||
      (props && isCustomStateClickOutside(props) && props.customState)
    )
      window.addEventListener('mousedown', handleWindowClick, {
        passive: true,
      });

    return () => window.removeEventListener('mousedown', handleWindowClick);
  }, [props, togglestate, handleWindowClick]);

  const handleKeyup = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (props && isCustomStateClickOutside(props))
          props.customToggleState(false);
        else setToggleState(false);
      }
    },
    [props],
  );

  useEffect(() => {
    window.addEventListener('keyup', handleKeyup, { passive: true });
    return () => window.removeEventListener('keyup', handleKeyup);
  }, [handleKeyup]);

  if (props && isCustomStateClickOutside(props)) return [elementRef] as R;
  return [togglestate, setToggleState, elementRef] as R;
};
