import { useQuery } from '@tanstack/react-query';
import { fetchContent } from '../helpers/axios/configs/contentAxios.ts';
import { TComponentResponseWrapper } from '../components/widgets';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store.ts';
import { TGenericObject } from '../types/commonTypes.ts';

export const useContent = (schemaName: string, contentGuid: string) => {
  const lng = useSelector((state: RootState) => state.language.lcid);
  return {
    ...useQuery<TGenericObject>({
      queryKey: ['translation', schemaName, contentGuid, lng],
      queryFn: async () => {
        const { data } = await fetchContent.get<
          TComponentResponseWrapper<TGenericObject>
        >(
          `/schemas/${schemaName.replace('component', 'content')}/${contentGuid}?locale=${lng}`,
        );
        return data.data;
      },
    }),
    lng,
  };
};
