import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { setSearch } from '../../redux/slices/searchSlice.ts';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store.ts';
import { useEffect, useRef, useState } from 'react';
import {
  TCustomStateClickOutside,
  useClickOutside,
} from '../../hooks/useClickOutside.ts';
import RecentSearchList from './recentSearchList.tsx';
import SearchList from './searchList.tsx';
import { FieldServiceCaseResponse } from '@signpost-hardware/management-portal-models/v1/field-service/cases/responses/field_service_case_response_pb';
import { DeviceResponse } from '@signpost-hardware/management-portal-models/v1/devices/responses/device_response_pb';
import { UserResponse } from '@signpost-hardware/management-portal-models/v1/users/responses/user_response_pb';
import { useQuery } from '@tanstack/react-query';
import { fetchDevice } from '../../helpers/axios/configs/deviceAxios.ts';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { useDisableScroll } from '../../hooks/useDisableScroll.ts';
import { fetchFieldService } from '../../helpers/axios/configs/fieldServiceAxios.ts';
import { fetchUser } from '../../helpers/axios/configs/userAxios.ts';
import { IRecentData } from '../../types/searchTypes.ts';
import SearchAlert from './searchAlert.tsx';
import { useDebounce } from 'use-debounce';
import { useContent } from '../../hooks/useContent.ts';
import { contentNameMapping } from '../../helpers/componentMappings/contentNameMapping.ts';
import { TranslationContext } from '../../context/translationContext.ts';
import { TGenericObject } from '../../types/commonTypes.ts';

type TSearchContentProps = {
  translation?: TGenericObject;
};

const SearchModal = () => {
  const translationSchemaName = 'search-content';
  const { data: translation, isLoading: isLoadingTranslation } = useContent(
    translationSchemaName,
    contentNameMapping[translationSchemaName],
  );
  const dispatch = useDispatch();
  const searchState = useSelector((state: RootState) => state.search);
  const [searchValue, setSearchValue] = useState<string>('');
  const [deferredSearchValue] = useDebounce(searchValue, 1000);
  const isStale = searchValue !== deferredSearchValue;
  const [recentData, setRecentData] = useState<IRecentData[]>(
    JSON.parse(localStorage.getItem('recentData') ?? '[]'),
  );
  const inputRef = useRef(null);
  const [searchRef] = useClickOutside<HTMLDivElement, TCustomStateClickOutside>(
    {
      ignoreClass: 'ignoreClickOutside',
      customState: searchState,
      customToggleState: (value: boolean) => handleCLose(value),
    },
  );
  useDisableScroll(searchState);

  const {
    data: devices,
    error: errorDevices,
    isLoading: isLoadingDevices,
  } = useQuery<DeviceResponse[]>({
    queryKey: ['devices', deferredSearchValue, 6],
    queryFn: async () => {
      const res = await fetchDevice.get('', {
        params: { $search: deferredSearchValue, $top: 6 },
      });
      return res.data.items;
    },
    enabled: !!deferredSearchValue,
  });

  const {
    data: cases,
    error: errorCases,
    isLoading: isLoadingCases,
  } = useQuery<FieldServiceCaseResponse[]>({
    queryKey: ['cases', deferredSearchValue, 6],
    queryFn: async () => {
      const res = await fetchFieldService.get('/cases', {
        params: { $expand: 'Device', $search: deferredSearchValue, $top: 6 },
      });
      return res.data.items;
    },
    enabled: !!deferredSearchValue,
  });

  const {
    data: users,
    error: errorUsers,
    isLoading: isLoadingUsers,
  } = useQuery<UserResponse[]>({
    queryKey: ['users', deferredSearchValue, 6],
    queryFn: async () => {
      const res = await fetchUser.get('', {
        params: { $search: deferredSearchValue, $top: 6 },
      });
      return res.data.items;
    },
    enabled: !!deferredSearchValue,
  });

  useEffect(() => {
    if (searchState && inputRef.current) {
      (inputRef.current as HTMLInputElement).focus();
    }
    setRecentData(JSON.parse(localStorage.getItem('recentData') ?? '[]'));
    setSearchValue('');
  }, [searchState]);

  const handleCLose = (value: boolean) => {
    setSearchValue('');
    dispatch(setSearch(value));
  };
  if (isLoadingTranslation) return <></>;

  const searchContent = ({ translation }: TSearchContentProps) => {
    if (errorDevices || errorCases || errorUsers)
      return <SearchAlert message={translation?.errorTryAgain ?? ''} />;
    if (cases?.length === 0 && devices?.length === 0 && users?.length === 0) {
      return (
        <SearchAlert
          message={translation?.nothingFound ?? ''}
          searchParam={deferredSearchValue}
        />
      );
    }
    return (
      <TranslationContext.Provider value={translation ?? {}}>
        <SearchList
          cases={cases}
          devices={devices}
          users={users}
          searchString={searchValue}
        />
      </TranslationContext.Provider>
    );
  };

  return (
    <div
      className={`${
        searchState ? 'block' : 'hidden'
      } absolute top-0 left-0 z-30 px-4 py-8 backdrop-blur-sm backdrop:opacity-80 bg-app-800/20 w-full h-screen flex justify-center items-start`}>
      <aside
        ref={searchRef}
        className='bg-alpha relative transform overflow-hidden rounded-xl text-left shadow-xl transition-all w-[45rem] h-[30rem]'>
        <section className='relative flex items-center'>
          <div className='absolute start-0 ps-3 pointer-events-none after:content-["|"] after:text-app-400 after:ml-2'>
            {isLoadingDevices || isLoadingCases || isLoadingUsers || isStale ? (
              <FontAwesomeIcon
                icon={faSpinnerThird}
                spin
                className='text-app-600'
                size='sm'
              />
            ) : (
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className='text-app-400'
                size='sm'
              />
            )}
          </div>
          <input
            ref={inputRef}
            className='block w-full p-5 pl-12 mt-0.5 text-sm text-gray-900 border-b-2 border-b-alpha-200 focus:outline-none placeholder:text-app-500'
            placeholder={translation?.placeholder}
            value={searchValue}
            onChange={(event) => setSearchValue(event.currentTarget.value)}
          />
        </section>

        {searchValue !== '' ? (
          searchContent({ translation })
        ) : (
          <TranslationContext.Provider value={translation ?? {}}>
            <RecentSearchList
              recentData={recentData}
              setRecentData={setRecentData}
            />
          </TranslationContext.Provider>
        )}
      </aside>
    </div>
  );
};

export default SearchModal;
