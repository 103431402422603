import {
  Dispatch,
  Fragment,
  MouseEvent,
  SetStateAction,
  useContext,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSearch } from '../../redux/slices/searchSlice.ts';
import { IRecentData } from '../../types/searchTypes.ts';
import SearchAlert from './searchAlert.tsx';
import { TranslationContext } from '../../context/translationContext.ts';

type TProps = {
  recentData: IRecentData[];
  setRecentData: Dispatch<SetStateAction<IRecentData[]>>;
};

const RecentSearchList = (props: TProps) => {
  const { recentData, setRecentData } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translation = useContext(TranslationContext);

  const handleRecentListItemClick = (guid: string, dataType: string) => {
    dispatch(setSearch(false));
    if (dataType === 'incident') navigate(`/incidents/${guid}`);
    if (dataType === 'device') navigate(`/devices/${guid}`);
    if (dataType === 'user') navigate(`/users/${guid}`);
  };

  const handleDeleteRecentListItem = (
    event: MouseEvent<HTMLButtonElement>,
    guid: string,
  ) => {
    event.stopPropagation();
    const recentDataTemp: IRecentData[] = recentData.filter(
      (item: IRecentData) => {
        if (item.type === 'incident') {
          return item.recentCase.guid !== guid;
        } else if (item.type === 'device') {
          return item.recentDevice.guid !== guid;
        } else if (item.type === 'user') {
          return item.recentUser.guid !== guid;
        }
      },
    );
    setRecentData(recentDataTemp);
    localStorage.setItem('recentData', JSON.stringify(recentDataTemp));
  };

  if (recentData.length === 0)
    return <SearchAlert message={translation.noRecentSearches} />;

  return (
    <section className='max-h-[27rem] overflow-y-auto pb-4 pt-3'>
      <header className='font-semibold text-app-600 px-4 py-2'>
        {translation.recentSearches}
      </header>
      <ul role='list'>
        {recentData.map((item, index) => (
          <Fragment key={index}>
            {item.recentDevice ? (
              <li
                key={`${item.recentDevice.guid}_${index}`}
                className='flex justify-between gap-x-6 py-2 -mb-3'>
                <div className='min-w-0 flex-auto bg-app-200 px-4 py-3'>
                  <div className='flex justify-between items-center gap-x-2'>
                    <span className='bg-app-500 text-app-100 text-sm me-2 px-2.5 py-1 mb-1 rounded-lg'>
                      {translation.device}
                    </span>
                    <button
                      className='ignoreClickOutsides'
                      onClick={(event) =>
                        handleDeleteRecentListItem(
                          event,
                          item.recentDevice.guid as string,
                        )
                      }>
                      <FontAwesomeIcon
                        icon={faX}
                        size='xs'
                        color='grey'
                        className='hover:text-app-700'
                      />
                    </button>
                  </div>
                  <div
                    className='hover:text-app-500 cursor-pointer'
                    onClick={() =>
                      handleRecentListItemClick(
                        item.recentDevice.guid as string,
                        'device',
                      )
                    }>
                    <p className=' leading-6'>
                      {item.recentDevice.manufacturer}{' '}
                      {item.recentDevice.modelName}
                    </p>
                    {item.recentDevice.serialNumber && (
                      <p className=' font-light'>
                        {item.recentDevice.serialNumber}
                      </p>
                    )}
                    {item.recentDevice.institutionLabel ||
                      (item.recentDevice.signpostLabel && (
                        <p className=' font-light'>
                          {item.recentDevice.institutionLabel ||
                            item.recentDevice.signpostLabel}
                        </p>
                      ))}
                    {item.recentDevice.user?.firstName &&
                      item.recentDevice.user?.lastName && (
                        <p className='truncate text-sm font-light mt-2'>
                          {item.recentDevice.user?.firstName}{' '}
                          {item.recentDevice.user?.lastName}
                        </p>
                      )}
                  </div>
                </div>
              </li>
            ) : item.recentCase ? (
              <li
                key={`${item.recentCase.guid}_${index}`}
                className='flex justify-between gap-x-6 py-2 -mb-3'>
                <div className='min-w-0 flex-auto bg-app-200 px-4 py-3'>
                  <div className='flex justify-between items-center gap-x-2'>
                    <span className='bg-app-500 text-app-100 text-sm me-2 px-2.5 py-1 mb-1 rounded-lg'>
                      {translation.incident}
                    </span>
                    <button
                      className='ignoreClickOutsides'
                      onClick={(event) =>
                        handleDeleteRecentListItem(
                          event,
                          item.recentCase.guid as string,
                        )
                      }>
                      <FontAwesomeIcon
                        icon={faX}
                        size='xs'
                        color='grey'
                        className='hover:text-app-700'
                      />
                    </button>
                  </div>
                  <div
                    className='hover:text-app-500 cursor-pointer'
                    onClick={() =>
                      handleRecentListItemClick(
                        item.recentCase.guid as string,
                        'incident',
                      )
                    }>
                    <p>
                      {item.recentCase.ourReference} - {item.recentCase.status}
                    </p>
                    {item.recentCase.billingContact && (
                      <p className='text-sm font-light'>
                        {item.recentCase.billingContact.firstName}{' '}
                        {item.recentCase.billingContact.lastName}
                      </p>
                    )}
                    {item.recentCase.serialNumber && (
                      <p className='text-sm  font-light'>
                        {item.recentCase.serialNumber}
                      </p>
                    )}
                  </div>
                </div>
              </li>
            ) : item.recentUser ? (
              <li
                key={`${item.recentUser.guid}_${index}`}
                className='flex justify-between gap-x-6 py-2 -mb-3'>
                <div className='min-w-0 flex-auto bg-app-200 px-4 py-3'>
                  <div className='flex justify-between items-center gap-x-2'>
                    <span className='bg-app-500 text-app-100 text-sm me-2 px-2.5 py-1 mb-1 rounded-lg'>
                      {translation.user}
                    </span>
                    <button
                      className='ignoreClickOutsides'
                      onClick={(event) =>
                        handleDeleteRecentListItem(
                          event,
                          item.recentUser.guid as string,
                        )
                      }>
                      <FontAwesomeIcon
                        icon={faX}
                        size='xs'
                        color='grey'
                        className='hover:text-app-700'
                      />
                    </button>
                  </div>
                  <div
                    className='hover:text-app-500 cursor-pointer'
                    onClick={() =>
                      handleRecentListItemClick(
                        item.recentUser.guid as string,
                        'user',
                      )
                    }>
                    <p>
                      {item.recentUser.firstName} {item.recentUser.lastName}
                    </p>
                    <p className='text-sm'>{item.recentUser.email}</p>
                    {item.recentUser.devices[0] && (
                      <p className='text-sm font-light'>
                        {item.recentUser.devices[0].serialNumber}
                      </p>
                    )}
                  </div>
                </div>
              </li>
            ) : (
              ''
            )}
          </Fragment>
        ))}
      </ul>
    </section>
  );
};

export default RecentSearchList;
